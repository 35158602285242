<template>
  <div class="main-page">
    <header>
      <nav>
        <div class="logo">Grounds AI</div>
        <div class="menu-icon" @click="toggleMenu">
          <i
            :class="{ 'fas fa-bars': !showMenu, 'fas fa-times': showMenu }"
          ></i>
        </div>
        <ul :class="{ 'show-menu': showMenu }">
          <li><a href="#home" @click="closeMenu">Home</a></li>
          <li><a href="#features" @click="closeMenu">Features</a></li>
          <li><a href="#collaborate" @click="closeMenu">Collaborate</a></li>
          <li><a href="#contact" @click="closeMenu">Contact</a></li>
        </ul>
      </nav>
    </header>

    <section id="home" class="hero">
      <div class="hero-content">
        <h1>AI for faster, better legal work</h1>
        <p>
          Our cutting-edge, reliable artificial intelligence makes legal work
          10x more efficient, so lawyers can focus on <b>JUDGMENT</b> and
          delivering exceptional results.
        </p>
        <a href="#contact" class="cta-button">Contact Us</a>
      </div>
    </section>

    <section id="features" class="features">
      <h2>Key Features</h2>
      <div class="feature-grid">
        <div class="feature-item">
          <i class="fas fa-code-branch"></i>
          <h3>Automated Documents</h3>
          <p>
            Automatically write and revise documents, with templates for the
            most common legal documents and intuitive intelligence for edge
            cases.
          </p>
        </div>

        <div class="feature-item">
          <i class="fas fa-code-branch"></i>
          <h3>Trustworthy Results</h3>
          <p>
            Unlike ChatGPT and other language models, our patent-pending AI
            features help avoid hallucinations and produce reliable legal work.
          </p>
        </div>

        <div class="feature-item">
          <i class="fas fa-robot"></i>
          <h3>Customizable AI Agents</h3>
          <p>
            Simulate litigation outcomes with unique AI agents for each
            participant in litigation: judges, opponents, clients, even
            yourself.
          </p>
        </div>

        <div class="feature-item">
          <i class="fas fa-search"></i>
          <h3>Intelligent Text Editor</h3>
          <p>
            Our in-browser text editor lets you draft and revise documents using
            AI, or manually, or in an interative AI-enabled process.
          </p>
        </div>

        <div class="feature-item">
          <i class="fas fa-users"></i>
          <h3>Multi-User Collaboration</h3>
          <p>
            Grounds lets multiple users--and helpful AI agents--interact and
            collaborate on strategy and legal drafting.
          </p>
        </div>
        <!-- <div class="feature-item">
          <i class="fas fa-code-branch"></i>
          <h3>No-Code Flows</h3>
          <p>Define specific sequences of actions for AI agents without writing any code.</p>
        </div> -->

        <div class="feature-item">
          <i class="fas fa-share-alt"></i>
          <h3>Automated Negotiation</h3>
          <p>
            Connect with counterparties and let AI agents run simulated
            negotiations of contested terms, so the parties can focus on value
            creation.
          </p>
        </div>
      </div>
    </section>

    <section id="team" class="team-section">
      <h2 class="section-title">Founded By Lawyers & Deep Learning Experts</h2>
      <!-- <h2 class="section-title">Made with passion & curiosity</h2> -->
      <div class="team-container">
        <div
          class="team-member"
          v-for="member in teamMembers"
          :key="member.name"
        >
          <img
            :src="member.image"
            :alt="`${member.name}, ${member.role}`"
            class="member-photo"
          />
          <div>
            <p class="member-name">{{ member.name }}</p>
            <p class="member-role">{{ member.role }}</p>
          </div>
          <button
            @click="member.showMore = !member.showMore"
            class="info-toggle"
          >
            {{ member.showMore ? "See Less" : "Learn More" }}
          </button>
          <div v-if="member.showMore" class="member-info">
            <p>{{ member.bio }}</p>
          </div>
        </div>
      </div>
    </section>

    <section id="collaborate" class="collaborate">
      <h2>Revolutionizing Legal Workflows with Artificial Intelligence</h2>
      <p>
        Grounds AI is bringing a new level of intelligence and efficiency to the
        legal system. Our AI-powered tools are designed to streamline legal work
        and help lawyers focus on what matters most: judgment. With our
        automated document generation, customizable AI agents, and multi-user
        collaboration features, we're transforming the way legal work is done.
      </p>
    </section>

    <section id="contact" class="contact">
      <h2>Get in Touch</h2>
      <p>
        Ready to revolutionize your workflow with Grounds AI? Contact us today
        to learn more and get started.
      </p>
      <form
        @submit.prevent="handleFormSubmit"
        action="https://docs.google.com/forms/d/e/1FAIpQLSfyG-RZLXRjVn9rffnYHE5duQf4Sb_XVdw7c1lAEUz81p3OcA/formResponse"
      >
        <input
          type="text"
          name="entry.1024150449"
          placeholder="Name"
          required
        />
        <input
          type="email"
          name="entry.56891630"
          placeholder="Email"
          required
        />
        <textarea
          name="entry.164989330"
          placeholder="Message"
          required
        ></textarea>
        <button type="submit">Send</button>
      </form>
    </section>

    <footer>
      <p>&copy; 2024 Grounds, Inc. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "MainPage",
  data() {
    return {
      showMenu: false,
      teamMembers: [
        {
          name: "Bahrad Sokhansanj, Ph.D.",
          role: "co-CEO",
          image: "/bahrad.jpg",
          bio: "Based in SoCal, Bahrad is a deep learning engineer, intellectual property lawyer, widely-cited computational biology researcher and former university professor.",
          showMore: false,
        },
        {
          name: "Joe Wilbert",
          role: "co-CEO",
          image: "/joe.jpg",
          bio: "Based in NY, Joe is a full-stack autodidact, business and insurance lawyer, and creator of multiple computer vision and NLP apps.",
          showMore: false,
        },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      this.showMenu = false;
    },
    handleFormSubmit(event) {
      console.log("event targ", event.target); // logs relevant info
      const form = event.target;
      const formData = new FormData(form);
      const formAction = form.action;

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`); // This will log each form field's name and value
      }

      fetch(formAction, {
        method: "POST",
        mode: "no-cors", // TODO: SECURITY AUDIT THIS
        body: formData,
      })
        // response is blank but it goes through
        .then((response) => {
          form.reset();
          alert("Thank you for your message! We will get back to you soon.");
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Oops! Something went wrong. Please try again later.");
        });
    },
  },
};
</script>

<style scoped>
/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global Styles */
.main-page {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.6;
}

/* Header */
header {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
}

nav ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #007bff;
}

.menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  nav ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  nav ul.show-menu {
    display: flex;
  }

  nav ul li {
    margin: 10px 0;
  }
}

/* Hero Section */
.hero {
  background-image: linear-gradient(to bottom right, #007bff, #00bfff);
  padding: 150px 0;
  text-align: center;
  color: #fff;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero h1 {
  font-size: 40px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 32px;
  }
}

.hero p {
  font-size: 24px;
  margin-bottom: 40px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.cta-button {
  background-color: #fff;
  color: #0f4278;
  border: none;
  padding: 15px 30px;
  font-size: 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
  font-weight: bold;
}

.cta-button:hover {
  background-color: #007bff;
  color: #fff;
}

/* Features Section */
.features {
  padding: 50px 20px;
  text-align: center;
  background-color: #f5f5f5;
}

.features h2 {
  font-size: 36px;
  margin-bottom: 60px;
  color: #333;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.feature-item {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

.feature-item:hover {
  transform: translateY(-10px);
}

.feature-item i {
  font-size: 48px;
  color: #007bff;
  margin-bottom: 20px;
}

.feature-item h3 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

.feature-item p {
  font-size: 16px;
  color: #666;
  flex-grow: 1;
}

/* Collaborate Section */
.collaborate {
  background-color: #f5f5f5;
  padding: 50px 20px;
  text-align: center;
}

.collaborate h2 {
  font-size: 36px;
  margin-bottom: 40px;
  color: #333;
}

.collaborate p {
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto;
  color: #666;
}

/* Team Section */
.team-section {
  background-color: #fff;
  padding: 50px 20px;
}

.section-title {
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.team-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto the next line */
  justify-content: space-around;
}
@media (min-width: 768px) {
  .team-container {
    flex-direction: row;
    justify-content: space-around;
  }
}

.team-member {
  flex: 1 1 300px; /* grow, shrink, and base width */
  margin: 10px; /* Adjust spacing between team members */
  text-align: center;
  min-width: 300px; /* Prevents items from becoming too narrow */
}

.member-photo {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-bottom: 4px;
}

.member-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.member-role {
  font-size: 1rem;
  margin-bottom: 4px;
}

.info-toggle {
  background-color: #e0e0e0;
  color: #333;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  border: none;
}

.info-toggle:hover {
  background-color: #ccc;
}

.member-info {
  background-color: #fff;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Contact Section */
.contact {
  padding: 50px 20px;
  text-align: center;
  background-color: #fff;
}

.contact h2 {
  font-size: 36px;
  margin-bottom: 40px;
  color: #333;
}

.contact p {
  font-size: 20px;
  margin-bottom: 40px;
  color: #666;
}

form {
  max-width: 600px;
  margin: 0 auto;
}

form input,
form textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color: #0056b3;
}

/* Footer */
footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}
</style>